<template>
	<div class="frameCon" style="height: 100%;">

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<!-- <el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">{{
					$t('i18nn_ca5a01a5adf20fe7') }}</el-button> -->
						<span class="tct_tit" style="font-size: 20px;">在线帮助</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig" style="text-align: left;">
					<div style="font-size: 16px;">
						<a href="https://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/helpDoc/HYTX_WMS_Client_HELP_CN.V7.pdf"
							target="_blank" title="CN.V7">
							<i class="el-icon-question"></i>
							<span>{{ $t('i18nn_54153ccc0c756e9f') }}</span>(中文)
						</a>


						<a href="https://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/helpDoc/HYTX_WMS_Client_HELP_EN.V7.pdf"
							target="_blank" title="EN.V7" style="padding-left: 10px;">
							<i class="el-icon-question"></i>
							<span>{{ $t('i18nn_54153ccc0c756e9f') }}</span>(EN)
						</a>
					</div>

					<!-- <span class="" v-if="!isSel"> -->
					<!-- <el-button v-if="!isSel" type="success" @click="openDioalog(null, $t('i18nn_c0246c55b9cac963'))" size="small"
						icon="el-icon-plus">{{ 'undefined' }}</el-button> -->
					<!-- </span> -->
					<!-- <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button> -->
				</el-col>
			</el-row>
		</div>

		<!-- <div class="filterCon" style="">
			<ul class="filterConList">
				
				<li>
					<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
					<el-input type="text" v-model="filterData.keyword" size="small" clearable @keyup.enter.native="initData()"
						maxlength="50" :placeholder="$t('i18nn_3ee4c9b76289e93a')" style="width: 180px;" />
				</li>
				<li>
					<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
						{{ $t('i18nn_1e7246dd6ccc5539') }}
					</el-button>
				</li>
			</ul>
		</div> -->

		<div class="docCon">
			<div class="leftDocMenu" style="">
				<ul v-loading="loading_load">
					<li v-for="(item, index) in tableData" :key="index" style="">
						<el-link :type="detData.id == item.id ? 'primary' : ''" icon="el-icon-document" :underline="false"
							@click="toShowDet(item)">{{ item.msgText }}</el-link>
					</li>
				</ul>
			</div>
			<div class="rightDocCon" style="">
				<div class="noticeCon" v-loading="loading_load_det">
					<h2 class="notice-title">

						<span>{{ detData.msgText }}</span>
						<!-- <span>
							<el-tag size="mini" v-if="'10' === detData.msgType" type="success">{{ detData.msgTypeName }}</el-tag>
							<el-tag size="mini" v-else-if="'20' === detData.msgType" type="warning">{{ detData.msgTypeName }}</el-tag>
							<el-tag size="mini" v-else type="info">{{ detData.msgTypeName }}</el-tag>
						</span> -->
					</h2>

					<div class="notice-msg">
						<div class="ql-snow">
							<div class="ql-editor" v-html="content">
							</div>
						</div>
					</div>
					<div class="notice-file" v-if='detData.attachments && detData.attachments.length > 0'>
						<span>{{ $t('i18nn_f5d43732e3f6cf4d') }}</span>：
						<ul>
							<li v-for="(item, index) in detData.attachments" :key="index">
								<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999" :fit="'contain'"
									:src="item.url" :preview-src-list="detData.attachments.map(itemPre => { return itemPre.url })">
									<div slot="error" class="image-slot">
										<i class="el-icon-document"></i>
									</div>
								</el-image>
								<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>

							</li>
						</ul>
					</div>
					<div class="notice-date" style="text-align: left; margin-top: 20px;">
						<i class="el-icon-date"></i>&nbsp;
						<span>{{ detData.noticeDate }}</span>
					</div>
				</div>
			</div>
		</div>

		<!-- <div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')"> -->
		<!-- <el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
				:height="$store.state.tableMaxHeight2" style="width: 100%" size="small">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50"
					align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

				<el-table-column prop="state" :label="$t('i18nn_6cdece641436d7ab')">
					<template slot-scope="scope">
						<el-tag v-if="'10'===scope.row.status" type="primary">{{scope.row.statusName}}</el-tag>
						<el-tag v-else-if="'20'===scope.row.status" type="warning">{{scope.row.statusName}}</el-tag>
						<el-tag v-else type="info">{{scope.row.statusName}}</el-tag>
					</template>
</el-table-column>

<el-table-column prop="msgTypeName" :label="$t('i18nn_2528332bfcac14b5')">
	<template slot-scope="scope">
						<el-tag v-if="'10'===scope.row.msgType" type="success">{{scope.row.msgTypeName}}</el-tag>
						<el-tag v-else-if="'20'===scope.row.msgType" type="warning">{{scope.row.msgTypeName}}</el-tag>
						<el-tag v-else-if="'30'===scope.row.msgType" type="danger">{{scope.row.msgTypeName}}</el-tag>
						<el-tag v-else type="info">{{scope.row.msgTypeName}}</el-tag>
					</template>
</el-table-column>

<el-table-column prop="msgText" :label="$t('i18nn_db1f861ffd10f028')">
	<template slot-scope="scope">
						<el-link type="primary" @click="toDet($event,scope.row)">
							{{ scope.row.msgText }}
						</el-link>
					</template>
</el-table-column>
<el-table-column prop="noticeDate" :label="$t('i18nn_43a3586339251494')"></el-table-column>

</el-table> -->

		<!-- <div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div> -->
		<!-- </div> -->

		<!--  内容查看 -->
		<!-- <dialogNoticeMoreTextView :openTime="moreTextOpenTime" :id="moreTextId"></dialogNoticeMoreTextView> -->

		<!--  附件查看 -->
		<!-- <whFileView :openTime="FileUploadViewOpenTime" :fileList="fileDataList"></whFileView> -->

	</div>
</template>
<script>
import {
	clearObjectVal
} from '@/utils/utils.js';
import {
	getDicData
} from '@/axios/common.js';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

// import dialogFileUpload from '@/components/WarehouseCenter2/components/dialogFileUpload.vue';

// import whFileView from '@/components/WarehouseCenter2/components/whFileView.vue';
// import dialogNoticeMoreTextView from '@/components/WarehouseCenter2/NoticeMana/dialogNoticeMoreTextView.vue';

export default {
	components: {
		// dialogFileUpload,
		// whFileView,
		// dialogNoticeMoreTextView
	},
	// props: {
	// 	// mobile:"",
	// 	isSel: {
	// 		default: function() {
	// 			return false
	// 		},
	// 		type: Boolean
	// 	},
	// },
	data() {
		return {
			// 	dialogFormVisible: false,
			// 	dialogFormStatus: 0, //0-新增，1-修改
			// 	dialogFormMsg: '',
			// dialogSelVisible: false,
			// FileUploadOpenTime: '',

			// FileUploadViewOpenTime: '',
			// fileDataList: [],

			// moreTextOpenTime: '',
			// moreTextId: '',

			// CateValue: [],
			loading: false,
			// form: {
			// 	"id": null, //"数据ID",
			// 	"showClient": null, //this.$t('i18nn_b40debd910bb74f2'),
			// 	"msgType": null, //this.$t('i18nn_2528332bfcac14b5'),
			// 	"msgText": null, //this.$t('i18nn_db1f861ffd10f028'),
			// 	"noticeDate": null, //this.$t('i18nn_43a3586339251494'),
			// 	"context": null, //this.$t('i18nn_bd0db0ad240cc83b'),
			// 	"msgTextKey": null, //"阿里云Key",
			// 	"attachments": [
			// 		// {
			// 		// 	"": null, //this.$t('i18nn_f5d43732e3f6cf4d')
			// 		// },
			// 	]
			// },

			// formRules: {
			// 	showClient: [{
			// 		required: true,
			// 		message: this.$t('FormMsg.Please_select'),
			// 		trigger: 'change'
			// 	}],
			// 	msgType: [{
			// 		required: true,
			// 		message: this.$t('FormMsg.Please_select'),
			// 		trigger: 'change'
			// 	}],
			// 	msgText: [{
			// 		required: true,
			// 		message: this.$t('FormMsg.Please_Enter'),
			// 		trigger: 'blur'
			// 	}],

			// },
			loading_load: false,
			loading_load_det: false,

			detData: {},
			content: '',

			tableData: [],
			//分页数据
			// pagination: this.$Utils.defaultPagination(),
			// selectOption: {
			// 	// wh_no: []
			// 	wh_notice_msg_type: [],
			// 	wh_notice_show_type: [],
			// },
			//查询，排序方式
			filterData: {
				// orderBy: 'id_', //排序字段
				// sortAsc: 'desc', //desc降序，asc升序
				// userName: '',
				// wh_notice_msg_type: '',
				// wh_notice_show_type: '',
				keyword: ''
			}
		};
	},
	activated() {
		// this.initData();
	},
	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();
		//数据字典
		// getDicData(['wh_notice_msg_type', 'wh_notice_show_type'],
		// 	(data) => {
		// 		this.selectOption.wh_notice_msg_type = data['wh_notice_msg_type'];
		// 		this.selectOption.wh_notice_show_type = data['wh_notice_show_type'];
		// 	});
		this.initData();
	},
	methods: {
		initData() {
			// this.pagination.current_page = 1;
			// this.currentSel = {};
			// this.multipleSelection = [];
			this.getPageData();
			// this.getDicData();
			// this.getWhNoListData();
			// this.$nextTick(() => {
			// 	this.$refs.multipleTable.doLayout();
			// });
		},
		goBack() {
			// console.log('window.history.length',window.history.length);
			// if (window.history.length > 2) {
			this.$router.go(-1);
			// } else {
			// 	this.$router.push({
			// 		name: 'WhDropShippingDashboard'
			// 	});
			// }
		},
		// toDet(event, row) {
		// 	event.stopPropagation();
		// 	this.$router.push({ name: "NoticeDet", query: { id: row.id } })
		// },

		//查询数据
		// serPageData() {
		// 	this.pagination.current_page = 1;
		// 	this.getPageData();
		// },

		//请求分页数据
		getPageData() {
			// let _this = this;
			this.loading_load = true;

			this.$http
				.put(this.$urlConfig.WhNoticeList, {
					// sortAsc: this.filterData.sortAsc,
					// orderBy: this.filterData.orderBy,
					// offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					// limit: this.pagination.page_size, //当前页显示数目
					offset: 1,
					limit: 500,
					// "status": "10",

					// showClient: this.filterData.wh_notice_show_type ? this.filterData.wh_notice_show_type : null,
					// msgType: this.filterData.wh_notice_msg_type ? this.filterData.wh_notice_msg_type : null,
					status: '10',//开启
					msgType: '40',//帮助
					// opModel: this.filterData.wh_op_type ? this.filterData.wh_op_type : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null
					// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
				})
				.then(({
					data
				}) => {
					// console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);

					this.loading_load = false;
					//表格显示数据
					this.tableData = data.rows;

					//默认显示
					if (this.tableData && this.tableData.length > 0) {
						this.toShowDet(this.tableData[0]);
					}
					//当前数据总条数
					// this.pagination.total = parseInt(data.total);
					//当前页数
					// this.pagination.current_page = parseInt(data.current);
					//当前页条数
					// this.pagination.page_size = parseInt(data.size);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},

		toShowDet(item) {
			this.getBaseData(item.id)
		},

		//请求分页数据
		getBaseData(id) {
			// let _this = this;
			this.loading_load_det = true;

			this.$http
				.get(this.$urlConfig.WhNoticeDet + '/' + id, {})
				.then(({
					data
				}) => {
					// console.log(this.$store.getters.getUserInfo);
					// console.log('分页，请求成功');
					// console.log(data);

					this.loading_load_det = false;
					//表格显示数据
					this.detData = data.data;
					this.getConTextData(this.detData.msgTextKey);
				})
				.catch(error => {
					console.log(error);
					// console.log('分页，请求失败');
					this.loading_load_det = false;
				});
		},
		//请求
		getConTextData(key) {
			// let _this = this;
			this.loading_load_det = true;

			this.$http
				.get(this.$urlConfig.WhNoticeMoreText + "?keyId=" + key, {})
				.then(({
					data
				}) => {
					// console.log(this.$store.getters.getUserInfo);
					// console.log('分页，请求成功');
					console.log(data);

					this.loading_load_det = false;
					//表格显示数据
					this.content = data.data;
				})
				.catch(error => {
					console.log(error);
					// console.log('分页，请求失败');
					this.loading_load_det = false;
				});
		},

		//选择数据后回调
		// selRow(event, row) {
		// 	event.stopPropagation();
		// 	this.$emit('selectRow', row);
		// },

		//查询数据字典
		// getDicData() {
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_notice_msg_type', 'wh_notice_show_type'])
		// 		.then(({
		// 			data
		// 		}) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				this.selectOption.wh_notice_msg_type = data.data['wh_notice_msg_type'];
		// 				this.selectOption.wh_notice_show_type = data.data['wh_notice_show_type'];

		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('tips.submitError');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('tips.submitRequestError'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// @import url(@/assets/css/client_module.less);
.docCon {
	display: flex;
	// height: calc(100% - 100px);
	height: 100%;
}

.leftDocMenu {
	width: 200px;
	// height: 100%;
	padding: 20px;
	background: #fff;
	overflow: auto;
	border-right: 1px solid #eee;

	ul {
		li {
			padding: 5px 0;
			padding-left: 20px;

			.el-link {
				line-height: 140%;
				padding: 8px 0;
			}

			// border-bottom: 1px solid #eee;
			// cursor: pointer;
			// &:hover {
			// 	color: #1890ff;
			// }
			// &.active {
			// 	color: #1890ff;
			// }
		}
	}
}

.rightDocCon {
	padding: 20px;
	background: #fff;
	flex: 1;
	overflow: auto;
}

.noticeCon {
	padding: 20px;
	background: #fff;
	line-height: 200%;

	.notice-title {
		text-align: center;
		font-size: 28px;
		line-height: 40px;
		font-weight: bold;
		color: #333;
	}

	.notice-date {
		text-align: center;
		font-size: 12px;
		color: #999;
	}

	.notice-msg {
		font-size: 14px;
		color: #666;
	}

	.notice-file {}
}
</style>
